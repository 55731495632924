import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, Card, CardContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CreditCard } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { useValue } from '../data';
import { useWindowDimensions } from '../rf/Responsive';
import { fetchJSON } from '../swutils';



function oneCard(t, w){
  return  <Card>
    <CardContent style={{ width : w, backgroundColor: '#788090', borderBottom : '10px'}}>
      <Typography variant="body" component="div">
        <CreditCard/> {t.tipstr}
      </Typography>
      <Typography variant="body2" color="primary">
        {`Egyenleg : ${t.egyenleg} EUR`} <br/> {`Állapot : ${t.estamp ? t.estamp : 'Nincs info'}`}
      </Typography>
    </CardContent>
  </Card>
}

export default  function KartyaInfoDlg({ open: { t }, close }) {
  const [trancard, setTrancard] = useState(false);
    
  useEffect(() => {
    fetchJSON('GET', '/tport/creditcard').then(setTrancard);
  },  []);

  const { width, height } = useWindowDimensions();

  let tc = [];
  if (trancard) {
    tc =  trancard.filter(t=>{return !!t.kartyaszam && t.tipus === 13}).map(t=>oneCard(t,width));
  }
  
  return <Formik initialValues={{tc}} validateOnChange onSubmit={close}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <Grid container spacing={2} style={{ marginLeft: 'auto', marginRight: 'auto', width: width > height ? '100%' : 316 }}>
            {tc}
          </Grid>
        </DialogContent>
        <DialogActions style={{ width: '100%', justifyContent: 'space-between'}}>
          <Button onClick={close}> OK </Button>
        </DialogActions>
      </div>
    </Form>
  </Formik>
};
