import { C, UI, I, F, DT, B, N, M, TEXT, PARAM, autoinc, readonly, pkey, notnull, notgen } from './types.mjs';
import { GPS } from './types.mjs';


export default {
	td_eszkoz: {
		id: UI(10, 'Azonosító', { autoinc }),
		subscription: PARAM('PushMessage subscription'),
		eszkoz: C(30, 'Eszköz azonosító'),
		statusz: N('Státusz', ['Telepítés alatt', 'Engedélyezve', 'Letiltva'], { defval: 1 }),
		kocsi: C(12, 'Rendszám'),
		naplozas: N('Naplózás', ['Kikapcsolva', 'Hibák', 'Alap', 'Részletes'], { defval: 2 }),
		eletjel: UI(4, 'Életjel (mp)', { defval: 60 }),
		tel: C(30,'Telefonszám'),
		resetcode: C(10,'Regisztrációs kód')
		/*		sofor: C(30, 'Sofőr'),
				ido: DT('Szerver idő'),
				eszkozido: DT('Eszköz idő'),
				version: C(10, 'Program verzió'),
				build: C(20, 'Build'),
				lat: F('Lat', { length: 9, prec: 6 }),
				lon: F('Lon', { length: 9, prec: 6 })*/
	},

	td_log: {
		id: UI(10, 'Azonosító', { autoinc }),
		tipus: N('Típus', ['Feedback', 'Alive', 'Error', 'Warning', 'Info', 'Debug']),
		eszkoz: UI(10, 'Eszköz', { fkey: { table: 'td_eszkoz', calc: ['kocsi'] } }),
		kocsi: C(12, 'Kocsi'),
		sofor: C(30, 'Sofőr'),
		knaplo: UI(8, 'KNapló', { fkey: { table: 'knaplo' } }),

		ido: DT('Szerver idő'),
		eszkozido: DT('Eszköz idő'),
		version: C(10, 'Program verzió'),
		build: C(20, 'Build'),

		lat: F('Lat', { length: 9, prec: 6 }),
		lon: F('Lon', { length: 9, prec: 6 }),
		accuracy: F('Accuracy', { length: 9, prec: 1 }),
		heading: F('Heading', { length: 6, prec: 2 }),
		speed: F('Speed', { length: 6, prec: 2 }),
		pos_stamp: DT('Position stamp'),

		network: C(30, 'Network'),

		storage: UI(10, 'Storage usage'),
		storageperc: F('Storage percent'),

		uzenet: TEXT('Üzenet'),

		kmora: UI(10, 'KM óra állás'),
		kmlat: F('Km Lat', { length: 9, prec: 6 }),
		kmlon: F('Km Lon', { length: 9, prec: 6 }),
		kmszamlalo: F('Virtuál km', { length: 8, prec: 2 }),
		legvonal: F('Légvonal', { length: 8, prec: 2 }),
	},

	knaplo: {
		sorszam: UI(10, 'Azonosító', { pkey, autoinc }),
		forrasid: C(60, 'Forras #id', { readonly }),
		celid: C(60, 'Cél #id', { readonly }),
		xid: C(60, 'ID', { readonly, notgen }),
		stamp: DT('Időpont', { readonly }),
		irany: N('Irány', ['Bejövő', 'Kimenő'], { notnull }),
		media: N('Média', ['SMS', 'Email', 'Icell'], { notnull }),

		jarat: C(14, 'Járat'),
		kocsi: C(12, 'Kocsi'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor: C(30, 'Sofőr'),

		msg: TEXT('Üzenet'),
		msg_hu: TEXT('Fordítás'),

		rnev: C(30, 'Record típus', { readonly }),
		ertek: TEXT('Értékek', { readonly }),
		nyelv: N('Nyelv', ['Magyar', 'Román']),
		csatolas: B('Csatolás'),
		tetel: UI(8, 'Tétel'),
		sablon: C(30, 'Sablon'),
		sablonreply: C(30, 'Válasz sablonra'),

		nyugta: DT('Nyugta'),
		rejtett: B('Rejtett'),
		trresult: N('Eredmény', ['Sikeres', 'Hibás']),
		trtxt: C(250),

		sendtour: DT('Útvonalküldés'),
		prioritas: N('Prioritás', ['Normál', 'Sürgős']),
		tabletfile: C(250, 'Talet file'),

		valasz: N('Visszajelzést kérünk', ['5 percen belül', '10 percen belül', '15 percen belül', '30 percen belül', '60 percen belül']),
		timeout: DT('Választ várunk eddig'),
		reply: DT('Választ kaptunk'),
		warning: DT('Figyelmeztettünk'),

		cuser: C(20, "tport user"),
		created: DT("Létrehozva"),
		kikuldve: DT('Kiküldve'),
		hibacnt: UI(3, 'Hiba'),
		kezbesitve: DT('Kézbesítve'),
		letoltve: DT('Letöltve'),
		hivatkozas: UI(8, 'Válasz erre'),
		nysofor: C(30, 'Nyugtázta'),
		lat: GPS('Lat'),
		lon: GPS('Lon'),
		km: UI(8, 'Km óra állás most'),
		utolsokm: UI(8, 'Utolsó állása'),
		virtualkm: UI(8, 'Virtuális km'),
		legvonal: UI(8, 'Légvonal'),
		td_statusz: TEXT('Státusz txt'),
		upload: C(250, 'Csatolmány'),
		uuid: C(250, 'UUID'),
		fparam: C(250,'fparam')
	},

	jarat: {
		pos: C(14, 'Pozíciószám', { pkey }),
		viszonylat: C(60, 'Viszonylat'),
		kezdet: DT('Kezdet'),
	},

	tetel: {
		sorszam: UI(10, 'Sorszám', { pkey }),
		jarat: C(14, 'Járat'),
		jelleg: N('Jelleg', ['Felrakó', 'Leakasztás', 'Felakasztás', 'Lerakó', 'Tankolás', 'Via pont', 'Raktár lerakó', 'Raktár felrakó']),
		kapocs: UI(8, 'Kapocs'),
		rejtett: B('Rejtett'),

		feladat: UI(8, 'Feladat ID'),
		fstamp: DT('SMS értesítés'),
		icellid: UI(8, 'Icell üzenet'),
		tdriverid: UI(8, 'tdriver üzenet'),
		tdfuvar: UI(8, 'tdriver fuvar'),

		pont: UI(8, 'Pont'),
		pdisplay: C(3, 'Kód'),
		prnev: C(60, 'Rövidnév', { notgen }),
		eterv: DT('Tervezett érkezés'),
		eteny: DT('Tényleges érkezés'),
		iterv: DT('Tervezett indulás'),
		iteny: DT('Tényleges indulás'),
		sterv: F('Súly terv', { prec: 4 }),
		steny: F('Súly teny', { prec: 4 }),
		km: F('Km óra állás', { prec: 1 }),

		kocsi: C(12, 'Vontató'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor: C(30, 'Sofőr'),
		sofor2: C(30, 'Sofőr 2'),
		epulet: C(60, 'Épület'),
		irsz: C(16, 'Irsz'),
		varos: C(30, 'Város'),
		cim: C(40, 'Cím'),
		orszag: C(2, 'Ország'),
		plat: GPS('Lat'),
		plon: GPS('Lon'),
		erkezes: DT('Érkezés (terv vagy tény)'),
		kstart: DT('Érkezés (késés)'),
		eok: B('OK'),
		iok: B('OK'),
		aruid: C(30, 'EKÁER szám'),
		aru: TEXT('Áru'),
		menny: F('Mennyiség'),
		rakref: C(30, 'Rakodási referencia'),
		rakmemo: C(30, 'Megjegyzés'),

		//vezénylés:
		vezenyles: N('Vezénylés', ['Érkezés', 'Indulás']),
		piheno: N('Pihenő', ['Nincs', '9', '11', '24', '45', '1 hét', 'Kilépő']),
		foghato: DT('Fogható'),
		mfoghato: DT('Fogható'),
		soformemo: C(60, 'Megjegyzés'),
		info: C(100, 'Szakasz infó'),
		elouto: N('Elő/utó rakás', ['Előrakás', 'Utórakás']),

		mstatusz: N('Megrendelés státusz', ['Előkészítés alatt', 'Megrendelve', 'Megszervezve', 'Megkezdve', 'Lebonyolítva', 'Pénzügyileg rendezve', 'Lemondva', 'Lemondva (Fizetős)', 'Lemondva és rendezve'], { notgen, readonly }),
		mtipus: N('Típus', ['Normál', 'Üres', 'Szerviz'], { notgen, readonly }),
		frigo: N('Frigo', ['Frigo', 'Frigo külön kérésre'], { notgen, readonly }),
		tapa:  B('TAPA', { notgen, readonly }),
		pottipus: N('Póttipus', ['Hűtő', 'Ponyva', 'Doboz'], { notgen, readonly }),
		fleiras: C(250, 'Feladat leírása', { notgen, readonly }),
		pothutes: B('Pótkocsi hűtés figyelés', { notgen, readonly }),
		poth_bfok: B('Hőfoktól', { notgen, readonly }),
		poth_tfok: B('Hőfokig', { notgen, readonly }),
		nemtankol: B('Nincs tankolás', { notgen, readonly }),

		info1: C(250,'Tájékoztató I.', { notgen, readonly }),
		info2: C(250,'Tájékoztató II.', { notgen, readonly }),
		info3: C(250,'Tájékoztató III.', { notgen, readonly })

	},

	kocsi: {
		frsz: C(12, "Rendszám", { pkey }),
		tipus: C(30, "Típus"),
		pottipus: N('Póttipus', ['Hűtő', 'Ponyva', 'Doboz'], { notgen }),
		jelleg: N('Jelleg', ['Önjáró', 'Vontatmány', 'Csereszekrény']),
		ptipus: C(30, "Típus"),
		plomba: C(30, "Plomba"),
		kme: F("Elöl (m)"),
		kmk: F("Középen (m)"),
		kmh: F("Hátul (m)"),
		bme: F("Elöl (m)"),
		bmk: F("Középen (m)"),
		bmh: F("Hátul (m)"),
		vegzaro: UI(5, "Végzáró"),
		csgatlo: C(30, "Csúszásgátló"),
		elvedo: C(30, "Élvédő"),
		pokroc: C(30, "Pokróc"),
		potnyitas: B('Nyitás érzékelő'),
		pothutes: B('Hűtés érzékelő'),
		potny_kezdet: DT("Nyitás figyelés kezdete"),
		potny_vege: DT("Nyitás figyelés vége"),
		poth_kezdet: DT("Hűtés figyelés kezdete"),
		poth_vege: DT("Hűtés figyelés vége"),
		poth_bfok: I(3, "Min. hőfok"),
		poth_btim: I(4, "Alsó limit (mp)"),
		poth_tfok: I(3, "Max. hőfok"),
		poth_ttim: I(4, "Felső limit (mp)"),
		tdriver: UI(10,"tdriver eszköz")
	},

	trancard: {
		id: C(30, 'Id', { pkey }),
		tipus: N('Típus', ['Routex', '', 'CIB', 'MOL', 'Myto', 'Vecsés', 'Mitry', 'Georgi', 'Waberer', 'Készpénz', 'Shell', 'Eurowag', 'Visa (EW)', 'K&H', 'Weilbach', 'Amsterdam', 'Viacard', 'Shell']),
		kartyaszam: C(30, 'Kártyaszám'),
		egyenleg : M('Egyenleg'),
		estamp: DT('Egyenleg dátum'),
		lejarat: C(5, 'Lejárat'),
		pin: C(4, 'PIN'),
		kocsi: C(12, 'Kocsi'),
	},

	sofor: {
		rnev: C(30, 'Rövid név', { pkey }),
		nev: C(30, 'Név'),
		tel: C(20, 'Tel'),
		jszam: C(16, 'Jogosítvány száma'),
		kulcskod: C(10, 'Kulcsszoft kód'),
		nyelv: N('Nyelv', ['Magyar', 'Román']),
		pciklus: UI(4,'Pihenő ciklus'),
		mciklus: UI(4,'Munka ciklus')
	},

/*	valtasigeny: {
		sorszam: UI(8,'Sorszám', {pkey}),
		sofor: C(30, 'Sofőr'),
		datum: D('Dátum'),
		irany: N('Irány',['Érkezés','Indulás'])
	},*/

	td_sablon: {
		rnev: C(30, 'Azonosítás', { pkey }),
		irany: N('Irány', ['Bejövő', 'Kimenő']),
		autokuldes: B('Automatikus küldés'),
		ismetles: N('Ismétlés', ['Mindig', 'Naponta', 'Hetente', 'Havonta']),
		cnt: UI(3, 'Max hányszor'),
		autornev: C(30, 'Erre válaszként'),
		text_hu: TEXT('Szövegr'),
		href_hu: C(250, 'Hivatkozás'),
		opt1_hu: TEXT('Opciók'),
		opt2_hu: TEXT('Opciók'),
		reply_hu: TEXT('Válasz opciók'),

		text_ro: TEXT('Szövegr'),
		href_ro: C(250, 'Hivatkozás'),
		opt1_ro: TEXT('Opciók'),
		opt2_ro: TEXT('Opciók'),
		reply_ro: TEXT('Válasz opciók'),

		mailto: C(250, 'Email'),
		smsto: C(250, 'SMS')
	},

	td_fuvar: {
		id: UI(8, 'Azonosító', { pkey, autoinc }),
		jarat: C(14, 'Járat'),
		viszonylat: C(30, 'Viszonylat'),
		mref: C(20, 'Referencia'),
		megbizas: UI(8, 'Megbízás'),
		szakasz: UI(8, 'Szakasz'),
		kocsi: C(12, 'Kocsi'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor: C(30, 'Sofőr'),
		sofor2: C(30, 'Sofőr #2'),
		msg: TEXT('Üzenet'),
		feladat: TEXT('Feladat'),
		leiras: TEXT('Útvonal'),
		ttxt1: TEXT('Pótkocsi felvétele'),
		ttxt2: TEXT('Áru összetétele'),
		ttxt3: TEXT('Pótkocsi leadása'),
		ttxt4: TEXT('Egyéb infó'),
		lpihi1: TEXT('Pihenő'),
		fpihi1: B('Fizetős'),
		lpihi2: TEXT('Pihenő'),
		fpihi2: B('Fizetős'),
		lpihi3: TEXT('Pihenő'),
		fpihi3: B('Fizetős'),
		frigo: N('Frigo', ['Frigo', 'Frigo külön kérésre']),
		pottipus: N('Póttipus', ['Hűtő', 'Ponyva', 'Doboz']),
		megapot: B('Mega'),
		pothutes: B('Pótkocsi hűtés figyelés'),
		poth_bfok: B('Hőfoktól'),
		poth_tfok: B('Hőfokig'),
		pottxt: TEXT('Pótkocsi'),
                bye: TEXT('Elköszönés')
	},

  mezo:{
		urlap: C(20,'Űrlap'),
		nev: C(20,'Név'),
		cimke: C(100,'Címke'),
		egyseg: C(30,'Egység'),
		oldal: C(20,'Oldal'),
		sorrend: UI(3,'Sorrend'),
		tipus: N('tipus',['Szöveg','Egész','Tört','Felsorolás','Bool','Dátum','Időpont','Időbélyeg','Memo']),
		hossz: C(5,'Hossz'),
		ertekek: TEXT('Értékek'),
		defval: C(100,'Alapért.'),
		aktiv: B('Aktiv')
	},

	ertekeles:{
		id: UI(8, 'Azonosító', { pkey, autoinc }),
		csoport: C(100,'Csoport'),
		kerdes: C(160,'Kérdés'),
		ertek: UI(2,'Értékelés'),
		memo: TEXT('Üzenet'),
		vsofor: C(30,'Válaszolt'),
		stamp: DT('Mikor'),		

		jarat:    C(14, 'Járat'),
		kocsi:    C(12, 'Kocsi'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor:    C(30, 'Sofőr'),
		jaratuser: C(20, 'Járatot leküldte'),
		lastuser: C(20,'Utolsó kommunikáció')
	},

	pontinfo:{
		id: UI(8, 'Azonosító', { pkey, autoinc }),
		jarat: C(14, 'Járat'),
		tetel: UI(8, 'Tétel'),
		pont: UI(8, 'Pont'),
		jelleg: N('Jelleg', ['Felrakó','Akasztó','Akasztó','Lerakó']),
		kocsi: C(12, 'Vontató'),
		sofor: C(30, 'Sofőr'),
		lat: GPS('Lat'),
		lon: GPS('Lon'),
		memo: TEXT('Infó'),
		stamp: DT('Mikor'),		
		upload: C(250, 'Csatolmány'),		
		okuser: C(20, 'Jóváhagyta'),
		okstamp: DT('Jóváhagyva'),
		_stamp: DT('stamp')
	}	

};



export const ELOKESZTIVE = 1;
export const FOLYAMATBAN = 2;
export const SIKERES = 3;
export const SIKERTELEN = 4;
export const TARGYTALAN = 5;

export const TENDER = 1;
export const KAMPANY = 2;

export const BEJOVO = 1;
export const KIMENO = 2;
export const BELSO = 3;



export const ADMIN = 1;
export const VEZETO = 2;
export const BEOSZTOTT = 3;
export const SAJAT = 1;
export const MIND = 2;
export const NEM = 0;
export const IGEN = 1;

export const JOG = [
	{},
	{ useradmin: true, kiadottarak: MIND, megbizasfa: true, helyettesites: true, knaplo: MIND },
	{ kiadottarak: MIND, megbizasfa: true, helyettesites: true, knaplo: MIND },
	{ kiadottarak: SAJAT, knaplo: SAJAT },

];
